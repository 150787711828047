<template>
  <LayoutManager>
    <div class="certificate-wrapper">
      <div>
        <div v-if="isLoading" class="h-300">
          <LxpLoader />
        </div>
        <b-row
          class="certificate-wrapper--div"
          :class="{ 'is-public': !isAuthenticated }"
          v-else
        >
          <div v-if="isAuthenticated">
            <LxpBreadcrumb
              class="ac__details__breadcrumb"
              :items="setBreadcrumbsPath"
              @click="navigateToLink"
            />
          </div>

          <b-col cols="12" class="p-0 cw-main">
            <Detail />
          </b-col>
        </b-row>
      </div>
    </div>
  </LayoutManager>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpBreadcrumb, LxpLoader } from "didactica";
import LayoutManager from "../Layout/LayoutManager.vue";
// components
import Detail from "./Detail.vue";
// mixins
export default {
  components: {
    LxpBreadcrumb,
    LxpLoader,
    LayoutManager,
    Detail
  },
  data() {
    return {
      isLoading: true,
      breadcrumbs: [
        {
          icon: require("@/assets/images/courseware/school-grey.svg"),
          text: this.$i18n.t("general.menu.my_learning"),
          to: { name: "my-learning" }
        }
      ],
      discoveryBreadcrumbs: [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$i18n.t("general.menu.discovery"),
          to: { name: "course-discovery" }
        }
      ],
      courseDetailBreadcrumbs: [
        {
          icon: require("@/assets/images/courseware/school-grey.svg"),
          text: this.$i18n.t("general.menu.my_learning"),
          to: { name: "my-learning" }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["language", "awardDetail"]),
    setBreadcrumbsPath() {
      if (this.$route.query.from === "discovery") {
        return this.discoveryBreadcrumbs;
      } else if (this.$route.query.from === "course-detail") {
        return this.courseDetailBreadcrumbs;
      } else {
        return this.breadcrumbs;
      }
    },
    isAuthenticated() {
      return this.$keycloak.authenticated;
    }
  },
  watch: {
    awardDetail(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.breadcrumbs.push({ text: this.awardDetail.course.display_name });
      }
    }
  },
  created() {
    this.$emit("update:layout", "main");
    this.fetchAwardDetails();
    if (this.$route.query.lang) {
      if (this.allLanguage?.find(l => l.id === this.$route.query.lang)) {
        this.language = this.$route.query.lang;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$i18n.locale = this.language;
      this.$store.commit("SET_LANGUAGE", this.language);
    }, 50);
  },
  methods: {
    fetchAwardDetails() {
      this.$store
        .dispatch("getAwardDetails", { id: this.$route.params.id })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.commit("SET_AWARD_DETAIL", this.achievementDetail);
        });
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    setBredcrumbVal() {
      this.breadcrumbs.push({
        icon: null,
        text: this.awardDetail?.course_title,
        to: `/coursewares/${this.awardDetail?.course_title}`
      });
    },
    goBack() {
      if (this.$route.name === "Awards") {
        this.navigateTo(`/coursewares/${this.courseId}`);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.certificate-wrapper {
  margin: 20px 0 0;
  .certificate-wrapper--div {
    &.is-public {
      .cw-main {
        margin: 16px auto;
        font-family: $font-family;
        .cw-main__heading {
          label {
            @include body-regular($brand-neutral-300);
          }
          h3 {
            @include h5($brand-neutral-700);
            cursor: pointer;
            margin-bottom: 32px;
          }
        }
      }
    }
  }
  .ac__details__breadcrumb {
    ::v-deep .breadcrumb {
      padding: 0 2rem;
      margin-bottom: 0;
    }
  }
}
</style>
